import {formatText, missingClass} from '~/lib/utils';

import clsx from 'clsx';
import {useMemo} from 'react';

export type TextProps = {
  as?: React.ElementType;
  className?: string;
  color?: 'default' | 'primary' | 'subtle' | 'notice' | 'contrast' | 'purple';
  format?: boolean;
  size?: 'lead' | 'copy' | 'fine' | 'subtext' | 'sm' | 'copyHeadline';
  width?: 'default' | 'narrow' | 'wide' | 'full';
  internal?: boolean;
  children: React.ReactNode;
  [key: string]: any;
};
const colors: Record<string, string> = {
  default: 'inherit',
  primary: 'text-primary/90',
  subtle: 'text-primary/50',
  notice: 'text-notice',
  contrast: 'text-contrast/90',
  purple: 'text-purple-600',
};

const sizes: Record<string, string> = {
  lead: 'text-lead font-medium',
  copy: 'text-copy',
  copyHeadline: 'text-copy font-bold',
  subtext: 'text-subtext',
  fine: 'text-fine subpixel-antialiased',
  sm: 'text-sm',
};

const widths: Record<string, string> = {
  default: 'max-w-prose',
  narrow: 'max-w-prose-narrow',
  wide: 'max-w-prose-wide',
  full: 'max-w-full',
};

export function Text({
  as: Component = 'span',
  className,
  color = 'default',
  format,
  size = 'copy',
  width = 'default',
  internal = false,
  children,
  ...props
}: TextProps) {
  const styles = clsx(
    missingClass(className, 'max-w-') && widths[width],
    missingClass(className, 'whitespace-') && 'whitespace-pre-wrap',
    missingClass(className, 'text-') && colors[color],
    sizes[size],
    className,
    internal && 'font-internal',
  );

  const style = useMemo(() => {
    return props.style
      ? {
          ...props.style,
        }
      : {};
  }, [props.style]);

  return (
    <Component {...props} className={styles} style={style}>
      {format ? formatText(children) : children}
    </Component>
  );
}
